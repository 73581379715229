<template>
  <div id="RangchaList">
    <!-- 让茶 -->
    <div>
      <div class="banner">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/banner_rangcha.jpg"
          alt=""
          srcset=""
        />
      </div>

       <!-- 让茶高山花系列 -->
       <div id="series_b" class="bg_huacha" style="margin-top: 72px">
        <div class="series_title" style="height: 200px">高山花茶系列</div>
        <div class="series_dec">
          <div class="dec_item">海拔800米以上</div>
          <div class="dec_item">福建高山乌龙茶</div>
          <div class="dec_item">搭配茉莉、竹香、</div>
          <div class="dec_item">桂花等东方香韵</div>
          <div class="dec_item">100%原叶茶萃取</div>
          <div class="dec_item">0糖0脂0能量</div>
          <div class="dec_item">自然清新、茶味醇爽</div>
        </div>
        <div class="item_box">
          <div class="item" @click="toDetail(1, 12)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_moliwulongcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">茉莉乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 13)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_zhuxiangwulongcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">竹香乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 14)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_guihuawulongcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">桂花乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 让茶高山纯系列 -->
      <div id="series_b" class="bg_c" style="margin-top: 72px">
        <div class="series_title" style="height: 200px">高山纯茶系列</div>
        <div class="series_dec">
          <div class="dec_item">优质高山好茶</div>
          <div class="dec_item">精选茶树品种</div>
          <div class="dec_item">100%原叶茶萃取</div>
          <div class="dec_item">0糖0脂0能量</div>
          <div class="dec_item">不添加防腐剂</div>
          <div class="dec_item">不添加香精</div>
          <div class="dec_item">自然本味，清香甘爽</div>
        </div>
        <div class="item_box">
          <div class="item" @click="toDetail(1, 5)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_gaoshanlvcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">高山绿茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 6)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_gaoshanwulongcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">高山乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 让茶草本系列 -->
      <div id="series_b" class="bg_e" style="margin-top: 72px">
        <div class="series_title" style="height: 200px">草本茶系列</div>
        <div class="series_dec">
          <div class="dec_item">清爽甘甜少苦涩</div>
          <div class="dec_item">以东方草本为灵感</div>
          <div class="dec_item">0糖0脂</div>
          <div class="dec_item">不添加香精</div>
          <div class="dec_item">不添加防腐剂</div>
        </div>
        <div class="item_box">
          <div class="item" @click="toDetail(1, 1)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_molihuacha.jpg"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">茉莉花茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>

          <div class="item" @click="toDetail(1, 11)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_p_damaiqingcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">大麦清茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 让茶无糖果味茶系列 -->
      <div id="series_a" class="bg_b">
        <div class="series_title" style="height: 238px">无糖果味茶系列</div>
        <div class="series_dec">
          <div class="dec_item">添加水果浓缩汁</div>
          <div class="dec_item">100%原叶茶萃取</div>
          <div class="dec_item">0糖0能量</div>
          <div class="dec_item">不添加防腐剂</div>
          <div class="dec_item">酸甜可口</div>
          <div class="dec_item">减能量不减美味</div>
        </div>
        <!-- benner -->
        <div class="item_box">
          <div class="item" @click="toDetail(1, 9)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/putaowulongcha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">葡萄乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 4)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/baitaoqingning.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">白桃青柠红茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>

          <div class="item" @click="toDetail(1, 15)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/qinmeilongjin.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">青梅龙井茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>

          <div class="item" @click="toDetail(1, 16)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/202401/ningcmolicha.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">柠C茉莉茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>

          
        </div>
      </div> 
      <!-- 他山集 -->
    </div>
    <PCConncatUs />
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import $ from "jquery";
import PCConncatUs from "@/components/PCConncatUs.vue";
export default {
  name: "RangchaList",
  components: {
    swiper,
    swiperSlide,
    PCConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
    };
  },
  methods: {
    toDetail(type, p_id) {
      this.$router.push(`/product/rangcha/detail?pid=${p_id}`);
    },
  },
  watch: {
    $route(newValue) {
      let height, banner_height;
      if (this.$route.query.h) {
        /*  banner_height = $(".banner").height(); */
        if (newValue.query.h == 1) {
          height = "60px";
        } else {
          height = (Number(newValue.query.h) - 1) * 600 + 60;
        }
      } else {
        height = "60px";
      }
      $("body,html").animate({ scrollTop: height }, 500);
      let _height = $(document).scrollTop();
    },
  },
  created() {
    this.$nextTick(() => {
      let height, banner_height;
      if (this.$route.query.h) {
        banner_height = $(".banner").height();
        height = (Number(this.$route.query.h) - 1) * 600 + 36 + banner_height;
        $("body,html").animate({ scrollTop: height }, 500);
      }
    });
  },
  mounted() {
    $("body,html").animate({ scrollTop: "60px" }, 0);
  },
};
</script>

<style scoped lang="less">
#RangchaList {
  .banner {
    width: 100%;
    /* height: 200px; */
    img {
      width: 100%;
      /*  height: 100%; */
    }
  }
  #series_a,
  #series_b,
  #series_c,
  #series_d {
    /* width: 1006px; */
    width: 100%;
    height: 320px;
    position: relative;
    margin-bottom: 284px;
    background-repeat: no-repeat;
    background-size: cover;
    .series_title {
      width: 60px;
      height: 142px;
      background: #45B035;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      line-height: 60px;
      letter-spacing: 5px;
      position: absolute;
      top: -37px;
      left: 24px;
      z-index: 998;
    }
    .series_dec {
      width: 260px;
      height: 230px;
      position: absolute;
      top: 20px;
      left: 122px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;

      z-index: 998;
      .dec_item {
        width: 24px;
        height: 100%;
        writing-mode: vertical-lr; //垂直方向，从左向右
        /* line-height: 32px; */
        /* text-align: center; */
        letter-spacing: 6px;
        float: left;
        margin-right: 12px;
      }
    }
    .item_box {
      width: 890px;
      height: 263px;
      position: absolute;
      left: 63px;
      top: 218px;
      cursor: pointer;
      z-index: 998;
      .item {
        width: 204px;
        height: 292px;
        float: left;
        margin-right: 14px;
        cursor: pointer;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        background: white;
        .img_box {
          width: 196px;
          height: 252px;
          overflow: hidden;
          img {
            width: 100%;
            height: 252px;
            transition: all 1s;
          }
        }
        .title {
          width: 196px;
          height: 36px;
          background: #fff;
          .title_left {
            float: left;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 36px;
          }
          .title_right {
            float: right;
            width: 36px;
            height: 36px;
            line-height: 30px;
            transition: all 1s;
            img {
              width: 36px;
              height: 4px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.1);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
    }
    .model {
      width: 1006px;
      height: 320px;
      position: absolute;
      left: 0;
      top: 0;
      background: black;
      opacity: 0.4;
      z-index: 997;
    }
  }

  .bg_a {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_a.jpg");
  }
  .bg_b {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/rangcha_guocha_banner.jpg");
  }
  .bg_c {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_c.jpg");
  }
  .bg_huacha {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_huacha.jpg");
  }
  .bg_d {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_d.jpg");
  }
  .bg_e {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_e.jpg");
  }
}
</style>
